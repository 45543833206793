<template>
    <div class="page">
        <webHeader :currentIndex="currentIndex"></webHeader>

        <div class="centerMain">
            <nav-menu :title="$t('webAssets.title')" :data="nav" :id="id" />
        </div>
    </div>
</template>

<script>
import webHeader from "@/components/web/webHeader.vue";
import navMenu from "@/components/web/nav-menu.vue";
import o2oDeposit from "@/views/web/assets/components/c2c/deposit.vue";
import record from "@/views/web/assets/components/c2c/menu.vue";
import withdrawal from "./components/c2c/withdrawal.vue";
import walletIndex from "./components/usdt/walletIndex.vue";
import withdraw from "./components/usdt/withdraw.vue";
import withdrawAddress from "./components/usdt/withdrawAddress.vue";
import recharge from "./components/usdt/recharge.vue";

export default {
    components: {
        webHeader, navMenu
    },
    data() {
        return {
            nav: [
                {
                    id: 1,
                    icon: '',
                    name: 'webAssets.menu.OTCAssets',
                    component: walletIndex,
                    children: [
                        {
                            id: 2,
                            icon: '',
                            name: 'webAssets.menu.WalletAssets',
                            component: withdrawAddress
                        },
                        {
                            id: 3,
                            icon: '',
                            name: 'webAssets.menu.USDTWithdraw',
                            component: withdraw
                        },
                        {
                            id: 4,
                            icon: '',
                            name: 'webAssets.menu.USDTDeposit',
                            component: recharge
                        }
                    ]
                },
                {
                    icon: '',
                    name: 'webAssets.menu.C2CTrade',
                    children: [
                        {
                            id: 10,
                            icon: '',
                            name: 'webAssets.menu.C2CDeposit',
                            component: o2oDeposit
                        },
                        {
                            id: 11,
                            icon: '',
                            name: 'webAssets.menu.C2CWithdrawal',
                            component: withdrawal
                        }
                    ]
                },
                {
                    id: 12,
                    icon: '',
                    name: 'webAssets.menu.TransactionRecord',
                    component: record
                }
            ],
            currentIndex: 6,
            id: "",
        }
    },
    watch: {
        $route: {
            handler: function (o, n) {
                this.id = this.$route.query.id;
                if (this.$route.query.id == 4) {
                    this.currentIndex = 7
                } else if (this.$route.query.id == 3) {
                    this.currentIndex = 8
                } else if (this.$route.query.id == 1) {
                    this.currentIndex = 6
                }
            },
            immediate: true,
        },

    },
    mounted() {

    },


}
</script>

<style lang="less" scoped>
.page {
    background-color: #141416;
    min-height: 100vh;

    .centerMain {
        padding: 20px 0;
        width: 1200px;
        min-height: calc(100vh - 80px);
        margin: 0 auto;
        display: flex;
    }


}
</style>