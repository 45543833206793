<template>
    <el-container class="container">
        <el-aside width="250px">
            <div class="nav-menu">
                <div class="m-title">
                    <span>{{ title }}</span>
                </div>
                <div class="m-list">
                    <div v-for="(item, index) in data" :key="index">
                        <div class="item" :class="[{ 'selected': selected === item.id }]" style="padding-left: 20px;"
                            @click="onMenuSelect(index, -1)">
                            <span>{{ $t(item.name) }}</span>
                        </div>
                        <div v-for="(sub, key) in item.children" :key="key">
                            <div class="item" :class="[{ 'selected': selected === sub.id }]" style="padding-left: 40px;"
                                @click="onMenuSelect(index, key)">
                                <span>{{ $t(sub.name) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-aside>
        <el-main style="background-color: #18191d;padding: none;">
            <component :is="map.component" :data="map.data" @reverse="onReceive" />
        </el-main>
    </el-container>
</template>

<script>
import { number } from 'echarts/lib/export'

export default {
    name: 'nav-menu',
    components: {},
    props: {
        data: {
            type: Array,
            default: []
        },
        title: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        }
    },
    watch: {
        id: {
            handler: function (val) {
                if (val) {
                    this.selected = Number(val)
                    this.onSelecteID(Number(val))
                }
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            map: {
                component: null,
                data: null
            },
            selected: -1
        }
    },

    methods: {
        onMenuSelect(section, row) {
            if (row === -1) {
                const item = this.data[section]
                if (item.id !== this.selected && item.id) {
                    this.selected = item.id
                    if (item.component) {
                        this.map.component = item.component
                        this.map.data = null
                    }
                }
            } else {
                const item = this.data[section].children[row]
                if (item.id !== this.selected) {
                    this.selected = item.id
                    if (item.component) {
                        this.map.component = item.component
                        this.map.data = null
                    }
                }
            }
        },
        onReceive(data) {
            this.data.forEach(item => {
                if (item.id === data.id) {
                    this.map = {
                        component: item.component,
                        data: data
                    }
                    this.selected = item.id
                } else {
                    if (item.children) {
                        item.children.forEach(sub => {
                            if (sub.id === data.id) {
                                this.map = {
                                    component: sub.component,
                                    data: data
                                }
                                this.selected = sub.id
                            }
                        })
                    }
                }
            })
        },
        onSelecteID(id) {
            this.data.forEach(item => {
                if (item.id === id) {
                    this.map = {
                        component: item.component,
                        data: null
                    }
                } else {
                    if (item.children) {
                        item.children.forEach(sub => {
                            if (sub.id === id) {
                                this.map = {
                                    component: sub.component,
                                    data: null
                                }
                            }
                        })
                    }
                }
            })
        }

    }
}
</script>

<style lang="less" scoped>
.container {
    width: 100%;
    min-height: 100%;
}


.nav-menu {
    width: calc(100% - 20px);
    height: 100%;
    background: #18191d;

    .m-title {
        padding-left: 20px;
        height: 56px;
        font-weight: 700;
        font-size: 16px;
        color: #fff;
        text-align: left;
        display: flex;
        align-items: center;
    }

    .item {
        display: flex;
        align-items: center;
        height: 56px;
        color: #828ea1;
        cursor: pointer;

        &:hover {
            background: #23262f;
            color: #fff;
        }
    }

    .selected {
        background: #23262f;
        color: #fff;
    }

}

::v-deep .el-main {
    padding: 0 !important;
}
</style>