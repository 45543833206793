<template>
    <div style="min-height: 500px;height: auto;">

        <van-list v-model="loading" :finished="finished" :finished-text="$t('common.nomore')"
            :loading-text="$t('jia-zai-zhong')" @load="onLoad">
            <table class="table">
                <tbody width="100%">
                    <tr>
                        <td>
                            <span>{{ $t('webAssets.time') }}</span>
                        </td>
                        <td>
                            <span>{{ $t('webAssets.order') }}</span>
                        </td>
                        <td>
                            <span>{{ $t('webAssets.status') }}</span>
                        </td>
                        <td>
                            <span>{{ $t('money') }}</span>
                        </td>
                    </tr>
                    <tr v-for="(item, key) in list" :key="key" @click="onItemClick(item)">
                        <td>
                            <span>{{ item.create_time | dateformat }}</span>
                        </td>
                        <td>
                            <span>{{ item.order_sn }}</span>
                        </td>
                        <td>
                            <span v-if="item.status == 0">{{ $t("Matching") }}</span>
                            <span v-if="item.status == 1">{{ $t("ToBePaid") }}</span>
                            <span v-if="item.status == 2">{{ $t("ToBeReviewed") }}</span>
                            <span v-if="item.status == 3">{{ $t("RechargeSuccessful") }}</span>
                            <span v-if="item.status == 4">{{ $t("RechargeFailed") }}</span>
                        </td>
                        <td>
                            <span>{{ item.price }}</span>
                        </td>
                    </tr>

                </tbody>
            </table>

        </van-list>
        <el-dialog :title="$t('TransactionDetails')" :visible.sync="dialogTableVisible">
            <detail :id="id" />
        </el-dialog>
        <el-dialog :title="$t('C2CDeposit')" :visible.sync="dialogPayVisible">
            <pay :id="id" />
        </el-dialog>
    </div>
</template>
<script>
import detail from "./c2cDepositDetail.vue";
import pay from "./c2cDepositPay.vue";
export default {
    components: {
        detail,
        pay
    },
    data() {
        return {
            list: [],
            page: 0,
            loading: false,
            finished: false,
            dialogTableVisible: false,
            dialogPayVisible: false,
            id: ""
        };
    },

    methods: {
        async onLoad() {
            this.page++;
            const { data } = await this.$http.get(
                "/home/user/rechargeCList?page=" + this.page
            );
            if (data) {
                this.loading = false;
                if (data.code === 200) {
                    if (data.data.list.length == 0) {
                        this.finished = true;
                    }
                    if (this.page == 1) {
                        this.list = data.data.list;
                    } else {
                        this.list.push(...data.data.list);
                    }
                }
            }
        },
        onItemClick(item) {
            if (item.status == 0) {//匹配中
                this.$toast(this.$t('WaitingToBeAssigned'));
            } else if (item.status == 1) {//待支付
                this.id = item.id;
                this.dialogPayVisible = true;
            } else if (item.status == 2) {//待审核
                this.id = item.id;
                this.dialogTableVisible = true;
            } else if (item.status == 3 || item.status == 4) {
                this.id = item.id;
                this.dialogTableVisible = true;
            }
        },

    },
};
</script>
<style lang="less" scoped>
.table {
    width: 100%;
    background: #131D2E;
    border-radius: 5px;
}

.table tr:first-child {
    border-top: none;

    td {
        color: #61688a !important;
        font-weight: 400 !important;
        cursor: default;
    }

}

.table tr {
    border-top: 1px solid #293448;
}

.table td {
    height: 44px;
    line-height: 44px;
    color: #aeb9d8;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer
}
</style>
  