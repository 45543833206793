<template>
  <div>
    <span class="topTv">{{ $t("AddressManger") }}</span>
    <div class="infoV">
      <div class="titleTv">{{ $t("ChooseAgreement") }}</div>
      <div class="addCent">
        <div class="protocolV">
          <van-radio-group v-model="protocolTxt" direction="horizontal">
            <van-radio checked-color="#f0b82d" name="ERC20">ERC20</van-radio>
            <van-radio checked-color="#f0b82d" name="TRC20">TRC20</van-radio>
            <van-radio checked-color="#f0b82d" name="OMNI">OMNI</van-radio>
          </van-radio-group>
        </div>
        <div class="addressV">
          <van-field v-model="addressVal" :placeholder="$t('tix.address')" />
        </div>
      </div>
      <div style="margin-left: 1rem; margin-right: 1rem;">
        <div class="submitTv pointer" @click="onSubmitClick">
          {{ $t("common.submit") }}
        </div>
      </div>
    </div>
    <div class="titleListTv" style="padding-top: 30px;">
      {{ $t("ListOfWalletAddresses") }}
    </div>
    <div style="margin-top: 1rem;">
      <div class="cardV">
        <span class="cardCode">{{ $t("contact.delbtn") }}</span>
        <span class="cardName">{{ $t("tix.address") }}</span>
        <span class="deleteTv">
          {{ $t("contact.delbtn") }}
        </span>
      </div>
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.nomore')"
        :loading-text="$t('jia-zai-zhong')"
        @load="onLoad"
      >
        <div
          v-for="item in list"
          :key="item.id"
          :title="item"
        >
          <van-swipe-cell>
            <div class="cardV">
              <span class="cardCode">{{ item.type }}</span>
              <span class="cardName">{{ item.address }}</span>
              <span class="deleteTv pointer" @click.stop="onItemDelClick(item)">{{
                $t("contact.delbtn")
              }}</span>
            </div>
          </van-swipe-cell>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mType: "",
      protocolTxt: "ERC20",
      addressVal: "",
      list: [],
      loading: false,
      finished: false,
    };
  },
  created() {
    this.mType = this.$route.params.selete;
  },
  methods: {
    async onLoad() {
      this.loading = true;
      const { data } = await this.$http.get("/home/user/userWalletList");
      if (data) {
        if (data.code === 200) {
          this.finished = true;
          this.list = data.data;
        }
      }
      this.loading = false;
    },
    async onSubmitClick() {
      if (!this.addressVal) {
        this.$toast(this.$t("PleaseEnterAddress"));
        return;
      }
      const postform = {
        type: this.protocolTxt,
        address: this.addressVal,
      };
      const { data } = await this.$http.post("/home/user/walletAdd", postform);
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t("common.success"));
          this.addressVal = "";
          this.onLoad();
        } else {
          this.$toast.fail(this.$t("common.error"));
        }
      }
    },
    async onItemDelClick(item) {
      const { data } = await this.$http.get(
        "/home/user/walletDel?id=" + item.id
      );
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t("common.success"));
          this.onLoad();
        } else {
          this.$toast.fail(this.$t("common.error"));
        }
      }
    },
    async onItemClick(item) {
      
    },
  },
};
</script>
<style lang="less" scoped>
.topTv {
  color: #c7d0dc;
  border-bottom: 1px solid #293448;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  font-size: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.infoV {
  margin-left: 16px;
  margin-top: 16px;
  width: 60%;
  background-color: white;
  border-radius: 5px;
  padding-bottom: 20px;
}
.titleTv {
  text-align: left;
  padding-left: 1rem;
  color: #363636;
  font-size: 15px;
  padding-top: 1rem;
}
.addCent {
}
.protocolV {
  margin-left: 1rem;
  margin-right: 1rem;
  padding-top: 10px;
  padding-bottom: 15px;
  display: flex;
  margin-top: 10px;
  .protocolTv {
    color: #363636;
    font-size: 15px;
    padding-right: 20px;
  }
}

.addressV {
  margin-left: 1rem;
  margin-right: 1rem;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  border: 1px solid #f0b90a;
  padding-left: 1rem;
  padding-right: 1rem;
}

.addressTv {
  color: #363636;
  font-size: 15px;
  padding-right: 20px;
}

.submitTv {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  font-size: 1rem;
  color: #fff;
  height: 2.75rem;
  width: 100%;
  background: #f0b90a;
}

.van-cell {
  flex: 1;
  padding: 0;
}
.cardV {
  height: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #293448;

  .cardCode {
    color: #aeb9d8;
    font-size: 15px;
    text-align: left;
    min-width: 100px;
  }

  .cardName {
    color: #aeb9d8;
    font-size: 10px;
    margin-top: 0.2rem;
    text-align: left;
    flex: 1;
  }

  .deleteTv {
    color: #ff0000;
    font-size: 15px;
    min-width: 100px;
    text-align: right;
  }
}
/deep/.van-radio__icon--checked .van-icon {
  background-color: #f0b90a;
  border-color: #f0b90a;
}
.titleListTv {
  padding-left: 20px;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  line-height: 60px;
  letter-spacing: 0;
  color: #c7d0dc;
  border-bottom: 1px solid #293448;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
