<template>
  <div>
    <span class="topTv">{{ $t("assets.withdraw") }}</span>
    <span class="titleTv1">{{ $t("tix.address") }}</span>
    <van-row class="addressV">
      <select class="addressSel" v-model="addressNmae">
        <option :value="-1">{{ $t("SelectWalletAddress") }}</option>
        <option
          :value="index"
          v-for="(item, index) in addressList"
          :key="index"
        >
          {{ item.type }}({{ item.address }})
        </option>
      </select>
    </van-row>
    <div class="inputV">
      <van-field
        :placeholder="$t('PleaseWithdrawalAmount')"
        type="number"
        v-model="form.number"
        clearable
      >
      </van-field>
    </div>
    <div class="moneyV">
      <span class="moneyTv1">{{ $t("AccountBalance") }}：{{ balance }}</span>
      <span class="moneyTv2 pointer" @click.prevent="all">{{ $t("tix.allbtn") }}</span>
    </div>
    <span class="passwordTv1">{{ $t("WithdrawalPassword") }}</span>
    <div class="passwordTv2">
      <van-field
        :placeholder="$t('VerifyPassword')"
        type="password"
        v-model="form.pay_pwd"
        maxlength="6"
        clearable
      ></van-field>
    </div>
    <div style="margin-left: 10px;margin-right: 10px;">
      <van-button
        class="confirmTv"
        :loading="loading"
        :loading-text="$t('jia-zai-zhong')"
        @click="submit"
      >
        {{ $t("Confirm") }}
      </van-button>
    </div>
    <div class="htmlTitleTv">
      {{ $t("WithdrawalInstructions") }}
    </div>

    <div class="htmlTv">
      <p v-html="txtHtml"></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cardItem: null,
      addressNmae: "-1",
      addressList: [],
      form: {
        pay_pwd: "",
        number: "",
      },
      txtHtml: "",
      loading: false,
      balance: "",
    };
  },
  created() {
    this.onLoadData();
    this.getinfo();
    this.getPayTxt();
  },
  methods: {
    async onLoadData() {
      const { data } = await this.$http.get("/home/user/userWalletList");
      if (data) {
        if (data.code === 200) {
          this.addressList = data.data;
        }
      }
    },
    //获取余额
    async getinfo() {
      const { data } = await this.$http.get("/home/user/withdraw");
      if (data) {
        if (data.code === 200) {
          this.balance = data.data.balance;
        }
      }
    },
    //获取提现说明
    async getPayTxt() {
      const { data } = await this.$http.get("/home/index/payText?id=2");
      if (data) {
        if (data.code === 200) {
          if (localStorage.getItem("lang") == "en") {
            this.txtHtml = data.data[0].en_content;
          } else if (localStorage.getItem("lang") == "ru") {
            this.txtHtml = data.data[0].ru_content;
          } else if (localStorage.getItem("lang") == "zh") {
            this.txtHtml = data.data[0].zh_content;
          } else if (localStorage.getItem("lang") == "fr") {
            this.txtHtml = data.data[0].fr_content;
          } else if (localStorage.getItem("lang") == "it") {
            this.txtHtml = data.data[0].it_content;
          } else {
            this.txtHtml = data.data[0].es_content;
          }
        }
      }
    },
    all() {
      this.form.number = this.balance;
    },
    async submit() {
      if (this.addressNmae != -1) {
        this.cardItem = this.addressList[this.addressNmae];
      } else {
        this.cardItem = null;
      }
      if (!this.cardItem || !this.form.pay_pwd || !this.form.number) {
        this.$toast.fail(this.$t("tix.complate"));
        return false;
      }
      const postform = {
        pay_pwd: this.form.pay_pwd,
        withdraw_num: this.form.number,
        card_id: this.cardItem.id,
      };
      this.loading = true;
      const { data } = await this.$http.post("/home/user/withdraw", postform);
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t("tix.submitok"));
        } else {
          this.$toast.fail(
            this.getlang(data.msg, localStorage.getItem("lang"))
          );
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.topTv {
  color: #c7d0dc;
  border-bottom: 1px solid #293448;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  font-size: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.titleTv1 {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  margin-top: 20px;
  font-size: 12px;
  color: #828ea1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.inputV {
  border: 1px solid #828ea1;
  font-size: 15px;
  color: #fff;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
}
.van-cell {
  background-color: #00000000;
}
/deep/.van-field__control {
  color: #fff !important;
  font-size: 12px;
}
.moneyV {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
}
.moneyTv1 {
  color: #828ea1;
  font-size: 12px;
}
.moneyTv2 {
  color: #f0b90b;
  font-size: 12px;
  margin-left: 20px;
}
.passwordTv1 {
  color: #828ea1;
  font-size: 12px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
}
.passwordTv2 {
  border: 1px solid #828ea1;
  font-size: 15px;
  color: #fff;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.confirmTv {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  height: 2.75rem;
  width: 100%;
  background: #f0b90b;
}
.van-button--default {
  border: 1px solid #f0b90b;
}
.htmlTitleTv {
  color: #828ea1;
  font-size: 14px;
  padding-left: 10px;
  text-align: left;
  margin-top: 40px;
}
.htmlTv {
  color: #828ea1;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  margin-top: 10px;
}
.addressV {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.addressSel {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: rgb(14, 26, 46);
  border: none;
  color: rgb(255, 255, 255);
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
.addressTv {
  color: #828ea1;
  font-size: 14px;
}
</style>
