<template>
    <div style="min-height: 500px;height: auto;">

        <van-list v-model="loading" :finished="finished" :finished-text="$t('common.nomore')"
            :loading-text="$t('jia-zai-zhong')" @load="onLoad">
            <table class="table">
                <tbody width="100%">
                    <tr>
                        <td>
                            <span>{{ $t('webAssets.time') }}</span>
                        </td>
                        <td>
                            <span>{{ $t('webAssets.order') }}</span>
                        </td>
                        <td>
                            <span>{{ $t('webAssets.status') }}</span>
                        </td>
                        <td>
                            <span>{{ $t('money') }}</span>
                        </td>
                    </tr>
                    <tr v-for="(item, key) in list" :key="key">
                        <td>
                            <span>{{ item.createtime | dateformat }}</span>
                        </td>
                        <td>
                            <span>{{ item.code }}</span>
                        </td>
                        <td>
                            <span v-if="item.status === '0'">{{ $t('dai-shen-he') }} </span>
                            <span v-if="item.status === '1'">{{ $t('tong-guo') }} </span>
                            <span v-if="item.status === '2'">{{ $t('bo-hui') }} </span>

                        </td>
                        <td>
                            <span>{{ item.recharge_num }}</span>
                        </td>
                    </tr>

                </tbody>
            </table>

        </van-list>


    </div>
</template>
<script>
export default {
    data() {
        return {
            list: [],
            loading: false,
            finished: false,
        };
    },

    methods: {
        async onLoad() {
            const { data } = await this.$http.get("/home/trade/get_recharges");
            if (data) {
                this.loading = false;
                if (data.code === 200) {
                    this.finished = true;
                    this.list = data.data;
                }
            }
        },
    },
};
</script>
<style lang="less" scoped>
.table {
    width: 100%;
    background: #131D2E;
    border-radius: 5px;
}

.table tr:first-child {
    border-top: none;

    td {
        color: #61688a !important;
        font-weight: 400 !important;
    }
}

.table tr {
    border-top: 1px solid #293448;
}

.table td {
    height: 44px;
    line-height: 44px;
    color: #aeb9d8;
    font-size: 15px;
    font-weight: 500;
}
</style>
  