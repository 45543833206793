<template>
  <div class="pager">
    <div class="topV">
      <div class="leftV">
        <span class="titleTv1">
          {{ $t("fund.usable") }}
        </span>
        <span class="titleTv2">
          {{ balance.total }}
        </span>
      </div>
      <div class="rightV">
        <span @click="onRechargeClick" class="buttonTv1 pointer">{{
          $t("assets.charge")
        }}</span>
        <span @click="onWithdeawClick" class="buttonTv1 pointer">{{
          $t("assets.withdraw")
        }}</span>
      </div>
    </div>
    <div class="bottomV">
      <div class="bottomItem">
        {{ $t("assets.tprofit") }}：
        <span v-if="tongji" class="bottomItemTv">
          {{ tongji.profit }}
          <!-- <div v-if="lang === 'en'">(INR)</div>
          <div v-if="lang === 'ru'">(RUB)</div>
          <div v-if="lang === 'zh'">(KBW)</div>
          <div v-if="lang === 'fr'">(JPY)</div>
          <div v-if="lang === 'it'">(BRL)</div>
          <div v-if="lang === 'es'">(MXN)</div> -->
        </span>
      </div>
      <div v-if="tongji" class="bottomItem" style="margin-top: 0px;">
        {{ $t("assets.tamount") }}：
        <span class="bottomItemTv">
          {{ tongji.price }}
        </span>
      </div>
      <div v-if="tongji" class="bottomItem" style="margin-top: 0px;">
        {{ $t("assets.torders") }}：
        <span class="bottomItemTv">
          {{ tongji.count }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: localStorage.getItem("lang"),
      balance: {},
      tongji:null
    };
  },
  created() {
    this.getinfo();
  },
  methods: {
    async getinfo() {
      const { data } = await this.$http.get("/home/user/index");
      if (data) {
        if (data.code === 200) {
          this.score = +data.data.userinfo.credit_score;
          this.userinfo = data.data.userinfo;
          this.balance = data.data.balance;
          this.tongji = data.data.tongji;
        }
      }
    },
    onRechargeClick() {
      this.$emit("reverse", {
        id: 4,
      });
    },
    onWithdeawClick() {
      this.$emit("reverse", {
        id: 3,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.pager {
  min-height: 100%;
  height: 100%;
  width: 100%;
}
.topV {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px;
  border-bottom: 1px solid #293448;
}
.leftV {
  display: flex;
  flex-direction: column;
}
.rightV {
  display: flex;
  align-items: center;
}
.buttonTv1 {
  margin: 0 20px;
  padding: 4px 26px;
  min-height: 2.572em;
  height: auto;
  font-size: 14px;
  color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0b90b;
}
.titleTv1 {
  font-size: 20px;
  color: #fff;
  text-align: left;
}
.titleTv2 {
  font-size: 28px;
  color: #fff;
  text-align: left;
  margin-top: 5px;
}
.bottomV {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.bottomItem {
  margin: 20px 0;
  font-size: 16px;
  color: #c7d0dc;
  display: flex;
  box-sizing: border-box;
}
.bottomItemTv {
  color: #fff;
  display: flex;
}
</style>
