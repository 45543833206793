<template>
    <div class="pager">
        <div class="box-title">
            <div class="menu" :class="[{ 'menuSelected': menuSelected == key }]" v-for="(item, key) in menu" :key="key"
                @click="onMenuSelected(key)">
                {{ $t(item) }}
            </div>
        </div>
        <div class="main">
            <depostiHistory v-if="menuSelected == 0" />
            <withdrawHistory v-if="menuSelected == 1" />
            <deposit v-if="menuSelected == 2" />
            <withdrawal v-if="menuSelected == 3" />
        </div>
    </div>
</template>

<script>
import deposit from './c2cDeposit.vue'
import withdrawal from './c2cWithdrawal.vue'
import depostiHistory from './depostiHistory.vue'
import withdrawHistory from './withdrawHistory.vue'
export default {
    components: {
        depostiHistory,
        withdrawHistory,
        deposit,
        withdrawal,
    },
    props: {
        data: {
            index: 0
        },
    },
    watch: {
        data: {
            handler: function (val) {
                if (val && val.index != this.menuSelected) {
                    this.menuSelected = val.index;
                }
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {

            menu: [
                "DepositHistory",
                "WithdrawHistory",
                "C2CDepositHistory",
                "C2CWithdrawalHistory"
            ],
            menuSelected: 0,
        }
    },
    created() {
    },
    methods: {

        onMenuSelected(index) {

            if (index === this.menuSelected) {
                return;
            }
            this.menuSelected = index;
        }

    }
}
</script>

<style lang="less" scoped>
.pager {
    min-height: 100%;
    height: 100%;
    width: 100%;
}

.box-title {
    height: 50px;
    width: 100%;
    border-bottom: 0.5px solid #828ea1;
    display: flex;
    flex-direction: row;
    align-items: center;

    .menu {
        height: 49px;
        padding: 0 16px;
        color: #828ea1;
        text-align: center;
        line-height: 49px;
        cursor: pointer;

        &:hover {
            background: #23262f;
            color: #f0b90b;
        }
    }

    .menuSelected {
        color: #f0b90b;
    }
}

.main {
    margin: 20px;
    height: 100%;
    width: calc(100% - 40px);
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 5px;
}
</style>