<template>
  <div>
    <span class="topTv">{{ $t("chongzhi") }}</span>
    <div class="centV">
      <van-tabs color="#fff" @click="onTabClick" :ellipsis="false">
        <van-tab
          :title="
            item.wallet_type
              ? item.product_type + '/' + item.wallet_type
              : item.product_type
          "
          v-for="(item, index) in tabList"
          :key="index"
        >
          <div class="infoV">
            <div class="infoLeftV">
              <span class="titleTv">{{ $t("TransactionScreenshot") }}</span>
              <van-uploader :after-read="afterRead">
                <div class="carmV">
                  <img
                    v-if="!imageUrl"
                    class="carmIv"
                    src="../../../../../assets/img/xiangji.svg"
                  />
                  <span v-if="!imageUrl" class="carmTv">{{
                    $t("UploadImage")
                  }}</span>

                  <img
                    :src="imageUrl ? imageUrl : ''"
                    ref="imageUrl"
                    v-if="imageUrl"
                    style="border-radius: 5px; max-width:100%;max-height:10rem;text-align:center"
                  />
                </div>
              </van-uploader>
              <div style="margin-right: 100px;">
                <div class="subTv pointer" @click="onSubClick(item)">
                  {{ $t("common.submit") }}
                </div>
              </div>
              <span class="cardV">{{ $t("RechargeInstructions") }}</span>

              <div class="htmlTv">
                <p v-html="txtHtml"></p>
              </div>
            </div>
            <div class="infoRightV">
              <img class="qrIv" :src="item.wallet_image" />
              <div class="addressV">
                <span class="addressTv">{{ item.wallet_url }}</span>
                <span
                  class="addressIv pointer"
                  @click.prevent="onCopyClick()"
                  :data-clipboard-text="item.wallet_url"
                >
                  {{ $t("copy") }}
                </span>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import Clipboard from "clipboard";
export default {
  data() {
    return {
      lang: localStorage.getItem("lang"),
      tabList: [],
      imageUrl: "",
      txtHtml: "",
    };
  },
  created() {
    this.getTabList();
    this.getPayTxt();
  },
  methods: {
    //获取类型
    async getTabList() {
      const { data } = await this.$http.get("/home/user/walletlist");
      if (data) {
        if (data.code === 200) {
          this.tabList = [];
          let dte = {
            product_type: this.$t("BankCard"),
            wallet_type: "",
          };
          // this.tabList.push(dte);
          this.tabList.push(...data.data.list);
        }
      }
    },
    //获取充值说明
    async getPayTxt() {
      const { data } = await this.$http.get("/home/index/payText?id=1");
      if (data) {
        if (data.code === 200) {
          if (this.lang == "en") {
            this.txtHtml = data.data[0].en_content;
          } else if (this.lang == "ru") {
            this.txtHtml = data.data[0].ru_content;
          } else if (this.lang == "zh") {
            this.txtHtml = data.data[0].zh_content;
          } else if (this.lang == "fr") {
            this.txtHtml = data.data[0].fr_content;
          } else if (this.lang == "it") {
            this.txtHtml = data.data[0].it_content;
          } else {
            this.txtHtml = data.data[0].es_content;
          }
        }
      }
    },
    //切换
    onTabClick(e) {
      this.imageUrl = "";
    },
    // 图片上传
    async afterRead(file) {
      const param = new FormData();
      param.append("file", file.file);
      const { data } = await this.$http.post("/home/user/uploadimg", param);
      if (data) {
        if (data.code === 200) {
          this.imageUrl = data.data.url;
        } else {
          this.$toast(this.getlang(data.msg, localStorage.getItem('lang')));
        }
      }
    },
    //复制
    onCopyClick() {
      const clipboard = new Clipboard(".addressIv");
      clipboard.on("success", (e) => {
        this.$toast.success(this.$t("common.success"));
      });
      clipboard.on("error", (e) => {
      });
    },
    //提交
    async onSubClick(item) {
      if (!this.imageUrl) {
        this.$toast(this.$t("PleaseSelectAPicture"));
        return;
      }
      this.showDialog = true;
      let postform = {
        wallet_id: item.id,
        img: this.imageUrl,
      };
      const { data } = await this.$http.post(
        "/home/user/coinRecharge",
        postform
      );
      if (data) {
        if (data.code === 200) {
            this.imageUrl='';
          this.$toast.success(this.$t("czhi.submitok"));
        } else {
          this.$message.error(this.$t("common.error"));
        }
      }
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.topTv {
  color: #c7d0dc;
  border-bottom: 1px solid #293448;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  font-size: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
}
/deep/.van-tabs__nav {
  background-color: #263043 !important;
}
/deep/.van-tab {
  color: #828ea1;
}
/deep/.van-tab--active {
  color: #fff;
}
.infoV {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 50px;
}
.infoLeftV {
  flex: 5;
  display: flex;
  flex-direction: column;
}
.titleTv {
  color: #fff;
  font-size: 15px;
  text-align: left;
}
.carmV {
  width: 80px;
  height: 80px;
  background: #ddd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.carmIv {
  width: 30px;
  height: 30px;
}

.carmTv {
  color: #555;
  font-size: 12px;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 12px;
}
.subTv {
  width: 100%;
  height: 43px;
  background-color: #f0b90b;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 33px;
}

.cardV {
  font-family: "Alibaba PuHuiTi";
  font-style: normal;
  font-weight: 700;
  text-align: start;
  padding-top: 2rem;
  padding-bottom: 0.62rem;
  color: #f0b90b;
  font-size: 15px;
  display: flex;
}
.htmlTv {
  padding-right: 1rem;
  text-align: start;
  color: #7f8fa4;
  font-size: 14px;
}
.infoRightV {
  flex: 5;
}
.qrIv {
  width: 123px;
  height: 123px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.addressV {
  display: flex;
  align-items: center;
  margin-top: 23px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #263043;
  border-radius: 2px;
}

.addressTv {
  font-size: 12px;
  font-weight: 700;
  color: #c7d0dc;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.addressIv {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #7f8fa4;
}
</style>
